
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { signIn } from 'next-auth/react'
import { RiGoogleFill } from 'react-icons/ri'

import { Button, Input } from '@yordlelabs/shared/web/ui'
import { Seo } from '@yordlelabs/website/util-components'

import { useTranslation } from '../utils'

export default function Login() {
  const { t } = useTranslation()
  return (
    <>
      <Seo scope="login" />
      <div className="flex flex-col justify-center mt-20 gap-6 w-80 mx-auto">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-700">
            {t('page.login.welcome')}
          </h1>
          <div
            className="text-sm text-gray-600 mt-2"
            dangerouslySetInnerHTML={{ __html: t('page.login.info') }}
          ></div>
        </div>

        <form
          className="flex flex-col gap-2"
          onSubmit={async (e) => {
            e.preventDefault()
            e.currentTarget.checkValidity()

            const formData = new FormData(e.currentTarget)
            await signIn('credentials', Object.fromEntries(formData.entries()))
          }}
        >
          <Input name="email" type="email" />
          <Input name="password" type="password" minLength={6} maxLength={24} />
          <Button variant="tonal">{t('page.login.continue')}</Button>
        </form>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-slate-300"></div>
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-white px-2 text-slate-600">
              {t('page.login.or')}
            </span>
          </div>
        </div>

        <div className="space-y-3">
          <Button
            variant="outlined"
            onClick={() => signIn('google')}
            className="w-full"
            Icon={RiGoogleFill}
          >
            Google
          </Button>
        </div>
      </div>
    </>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  